// extracted by mini-css-extract-plugin
export var card = "WinterOfDoingDetailPage-module--card--Zox7m";
export var companyDescription = "WinterOfDoingDetailPage-module--companyDescription--ESyLx";
export var companyImage = "WinterOfDoingDetailPage-module--companyImage--scNDO";
export var companyLeft = "WinterOfDoingDetailPage-module--companyLeft--W17bO";
export var detail = "WinterOfDoingDetailPage-module--detail--JSLOA";
export var header = "WinterOfDoingDetailPage-module--header--fvTqU";
export var learnMore = "WinterOfDoingDetailPage-module--learnMore--i434D";
export var navMobile = "WinterOfDoingDetailPage-module--navMobile--2rEXt";
export var parent = "WinterOfDoingDetailPage-module--parent--6o9YX";
export var projectCard = "WinterOfDoingDetailPage-module--projectCard--LH5Mv";
export var startup = "WinterOfDoingDetailPage-module--startup--8i+2O";
import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import LeadCaptureVersionTwo from '@components/LeadCaptureVersionTwo/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
// import Slider from 'react-slick';
import { companyDetails } from '../../misc/cwod-projects';
import * as styles from './WinterOfDoingDetailPage.module.scss';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { graphql } from 'gatsby';

import 'swiper/css/bundle';
import 'swiper/css';
import FooterV2 from '@components/FooterV2/index';

// const Image = loadable(() => import("../../components/extra/image"));
const CustomSnowfall = loadable(() =>
  import('../../components/CustomSnowfall/index'),
);

export default function WinterOfDoingDetailPage({ pageContext, data }) {
  // console.log(pageContext);
  //   const dispatch = useContext(GlobalDispatchContext);
  //   const state = useContext(GlobalStateContext);
  const typeform = 'zz81fEK8';
  const isMobile = useResizer();
  const [navMobile, setNavMobile] = useState(false);
  const [animation, setAnimation] = useState('false');
  const images = data.images.nodes;

  const swiperRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const settings = {
    /* slidesPerColumn: isMobile ? 3 : 2,
		slidesPerColumnFill: 'row', */
    slidesPerView: isMobile ? 1 : 3,
    loop: true,
    spaceBetween: 30,
    speed: 300,
    grabCursor: true,
    noSwiping: false,
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    // },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };
  //images
  const image = images.find(
    (ele) => ele.name == companyDetails[pageContext.companyInfo.company].image,
  );
  const companyLogo = getImage(image.childImageSharp);

  const remainingProjects = pageContext.allProjects.filter(
    (project) => project.company !== pageContext.companyName,
  );

  const options = (
    <ul
      onClick={() => {
        setAnimation('animate__slideOutDown');
        setTimeout(() => {
          setNavMobile(false);
        }, 700);
      }}
    >
      <li>
        <Link to="/">Crio.Do</Link>
      </li>
      <li>
        {' '}
        <a href=".#why-wod">Why WOD</a>
      </li>
      <li>
        <Link to="../">
          {' '}
          <StaticImage
            placeholder="blurred"
            src="../../images/externship/WoD_Logo_Crio_Beaver.png"
            alt="Beaver"
            loading="lazy"
            style={{ width: '100px' }}
          ></StaticImage>
          {/* <Image
            filename="externship/WoD_Logo_Crio_Beaver.png"
            style={{ width: '100px' }}
          ></Image> */}
        </Link>
      </li>
      <li>
        {' '}
        <a href=".#externships">Externships</a>
      </li>

      <li>
        <a href=".#schedule">Schedule</a>
      </li>
    </ul>
  );

  return (
    <>
      {' '}
      <SEO
        title={'Crio Winter of Doing | ' + pageContext.companyInfo.company}
        description="Work on challenging projects for the most exciting startups in the country. Join India's largest tech externship program for developers."
      ></SEO>
      <span>
        {navMobile && (
          <div
            className={`${styles.navMobile} ${animation} flex flex-col items-center text-center  md:hidden`}
          >
            {/* <CustomSnowfall></CustomSnowfall>{" "} */}
            <div className="" style={{ zIndex: '2' }}>
              {' '}
              <FontAwesomeIcon
                icon={['fal', 'times']}
                size="2x"
                className="cursor-pointer"
                onClick={() => {
                  setAnimation('animate__slideOutDown');
                  setTimeout(() => {
                    setNavMobile(false);
                  }, 700);
                }}
              />
              {options}
            </div>
            <div
              className="w-full"
              style={{ position: 'absolute', bottom: '-100px' }}
            >
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/Mountain.png"
                alt="Mountain"
                loading="lazy"
                style={{ height: '100%', zIndex: '1' }}
              ></StaticImage>
              {/* <Image
                filename="externship/Mountain.png"
                style={{ height: '100%', zIndex: '1' }}
              ></Image> */}
            </div>
          </div>
        )}
        <div
          className="flex w-full justify-center pt-3 md:hidden "
          // data-aos="slide-down"
          // data-aos-delay="800"
          // data-aos-duration="1000"
          style={{ position: 'absolute', zIndex: '9' }}
        >
          <StaticImage
            placeholder="blurred"
            src="../../images/externship/WoD_Logo_Crio_Beaver.png"
            alt="Mountain"
            loading="lazy"
            style={{ width: '100px' }}
          ></StaticImage>

          {/* <Image
            filename="externship/WoD_Logo_Crio_Beaver.png"
            style={{ width: '100px' }}
          ></Image> */}
          <div
            onClick={() => {
              setNavMobile(true);
              setAnimation('animate__slideInUp');
            }}
            style={{
              position: 'absolute',
              zIndex: '9',
              right: '0',
              marginRight: '20px',
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'bars']}
              size="2x"
              className="cursor-pointer"
            />
          </div>
        </div>
        <header
          className={`${styles.header} flex hidden justify-center md:flex`}
        >
          {options}
        </header>
        <div className="relative">
          <div className="hidden lg:block">
            <StaticImage
              layout="fullWidth"
              placeholder="blurred"
              src="../../images/externship/HeaderimageV2.png"
              alt="Hero"
              loading="eager"
              className="w-full"
            ></StaticImage>
          </div>
          <div className=" lg:hidden">
            <StaticImage
              layout="fullWidth"
              placeholder="blurred"
              src="../../images/externship/MobileHeader.png"
              alt="Hero"
              loading="eager"
              className="w-full"
            ></StaticImage>
          </div>{' '}
          <CustomSnowfall></CustomSnowfall>
        </div>
        <div className={`${styles.detail} pt-16 pb-16`}>
          <div className="container mb-4 flex flex-col ">
            <span style={{ marginTop: '-500px', zIndex: '9' }}>
              <div className={styles.card}>
                <div className="row">
                  <div
                    className="col-12 col-lg-3 mb-3 md:mb-0"
                    id={styles.companyLeft}
                  >
                    <GatsbyImage
                      loading="eager"
                      image={companyLogo}
                      alt={'company'}
                      className={`mb-6 ${styles.companyImage}`}
                    />
                    {/* <Image
                      className={`mb-6 ${styles.companyImage}`}
                      filename={
                        companyDetails[pageContext.companyInfo.company].image
                      }
                      // hover={pageContext.companyInfo.image}
                    ></Image> */}

                    <div className="mb-4">
                      <h6 className="m-0">Industry</h6>
                      <p> {pageContext.companyInfo.industry}</p>
                    </div>
                    <div className="mb-4">
                      <h6 className="m-0">Website</h6>
                      <p>
                        {' '}
                        <a
                          href={'https://' + pageContext.companyInfo.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {pageContext.companyInfo.website}
                        </a>
                      </p>
                    </div>
                  </div>{' '}
                  <div className="col-12 col-lg-9">
                    <div style={{ color: '#708598' }} className="mb-1 ">
                      {' '}
                      <Link
                        to="/crio-winter-of-doing/"
                        className="flex items-center"
                      >
                        {' '}
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-left']}
                          className="mr-2"
                          // size="2x"
                        ></FontAwesomeIcon>
                        <p className="m-0">Back Home</p>
                      </Link>
                    </div>
                    <h1> {pageContext.companyInfo.company}</h1>
                    <p className={styles.companyDescription}>
                      {' '}
                      {ReactHtmlParser(pageContext.companyInfo.about)}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.card}>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <h2 className="mb-3">Projects</h2>
                    {pageContext.projects.map((project, idx) => (
                      <div
                        key={idx}
                        className="mt-6 pb-5"
                        style={{
                          borderBottom:
                            idx !== pageContext.projects.length - 1 &&
                            '1px solid #999',
                        }}
                      >
                        <h5>{project.title}</h5>
                        <p className={styles.companyDescription}>
                          {ReactHtmlParser(project.description)}
                        </p>
                      </div>
                    ))}
                  </div>{' '}
                </div>
              </div>
            </span>
          </div>
          <div className="flex justify-center">
            <LeadCaptureVersionTwo
              // data-aos="fade-up"
              // data-aos-duration="1000"
              // data-aos-delay="800"
              background="#6EBAE6"
              hoverbackground="#1B2A37"
              color="#06131F"
              hovercolor="#fff"
              link={typeform}
              type="CWOD"
              location="DETAIL_PAGE"
            >
              Apply Now
            </LeadCaptureVersionTwo>
          </div>
        </div>
        <div className={`${styles.startup} pt-16 pb-16`}>
          <div
            className="container flex flex-col text-center"
            // data-aos="fade-up"
            // data-aos-delay="50"
            // data-aos-duration="1000"
          >
            <h3 className="mb-14">Explore more projects</h3>
            <div className="bodyText2">
              {' '}
              <Swiper
                ref={swiperRef}
                {...settings}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                autoplay
                modules={[Navigation, Autoplay]}
              >
                {remainingProjects.map((project, idx) => {
                  const image = images.find((ele) => ele.name == project.logo);
                  const companyLogo = getImage(image.childImageSharp);

                  return (
                    <SwiperSlide key={`${idx}`}>
                      <span className="pr-4 pl-4 md:pr-0 md:pl-0">
                        <Link
                          key={idx}
                          to={
                            '/crio-winter-of-doing/' +
                            project.company.replace(/\s+/g, '-').toLowerCase()
                          }
                        >
                          <div
                            className={styles.projectCard}

                            // data-aos="slide-up"
                            // data-aos-delay="50"
                            // data-aos-duration="1000"
                          >
                            <div>
                              <GatsbyImage
                                loading="lazy"
                                image={companyLogo}
                                alt={'company'}
                                style={{ width: '150px' }}
                                className="mb-2"
                              />
                              {/*                             
                            <Image
                              filename={project.logo}
                              style={{ width: '150px' }}
                              className="mb-2"
                            ></Image> */}
                            </div>
                            <h6>{project.title}</h6>

                            <div
                              style={{
                                position: 'absolute',
                                bottom: '15px',
                              }}
                            >
                              <a
                                href={
                                  '/crio-winter-of-doing/' +
                                  project.company
                                    .replace(/\s+/g, '-')
                                    .toLowerCase()
                                }
                              >
                                <h6 className={styles.learnMore}>
                                  Learn More{' '}
                                </h6>
                              </a>
                            </div>
                            <div
                              style={{ marginBottom: '20px', height: '20px' }}
                            ></div>
                          </div>
                        </Link>
                      </span>
                    </SwiperSlide>
                  );
                })}
                <div className="mt-10 flex w-full items-center justify-center gap-x-4">
                  <div
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                    ref={navigationPrevRef}
                    className=" top-1/2 left-0 z-10 -translate-x-1/2 -translate-y-1/2 transform  cursor-pointer hover:opacity-75"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-left']}
                    ></FontAwesomeIcon>
                  </div>{' '}
                  <div
                    onClick={() => swiperRef.current.swiper.slideNext()}
                    ref={navigationNextRef}
                    className=" top-1/2 right-0 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-right']}
                    ></FontAwesomeIcon>
                  </div>
                </div>
              </Swiper>
            </div>{' '}
          </div>
        </div>
        <FooterV2 />
        {/* <Footer></Footer> */}
      </span>
    </>
  );
}

export const pageQuery = graphql`
  query {
    ...externshipCompaniesFragment
  }
`;
